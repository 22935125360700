import './App.css';
import { Player } from '@lottiefiles/react-lottie-player';
import YouTube from 'react-youtube';

function App() {
  function scrollToTop() {

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
  const videoId = 'mI3kNhqiL_c';

  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 1, // Autoplay the video
    },
  };

  const onReady = (event) => {
    // Access to player in all event handlers via event.target
    event.target.playVideo();
  };
  return (
    <div className="App">
      <div class="background-container">
        <header className="header">
          <p>ColorGame<span>.me</span></p>
          <div className='nav-social'>
            <a href='https://www.instagram.com/super_earn27?igsh=MWYyeDVqNGtkMnpieQ==' target='_blank' rel="noreferrer">Follow <i class="fa-brands fa-instagram"></i></a>
            <a href='https://telegram.dog/superfiewin' target='_blank' rel='noreferrer' >Join <i class="fa-brands fa-telegram"></i></a>
          </div>
        </header>
        <div className="homeContainer">
          <div className="homeContent">
            <h1>
              Looking for a fun and Trustworthy online Gaming App ?
            </h1>
            <h4>
              TC Colour Game is your Answer !
            </h4>
            <a href='https://telegram.dog/superfiewin' target='_blank' rel='noreferrer' class="gradient-button">Join Our Telegram Channel</a>
          </div>
          <div className="logoContainer">
            <img src="./Asserts/logo.png" alt=""></img>
          </div>
        </div>
      </div>
      <div className="features">
        <div className="justorder-features">
          <h1 className="why-jo">Why ColorGame<span>.me</span> ?</h1>
          <div className="features-container">
            <div className="feature-card">
              <Player
                autoplay
                loop
                src="https://lottie.host/0106fb3c-c3eb-4d7b-a9bf-8567e12c0be0/6osOuVneX6.json"
              >
              </Player>
              <h3>User Friendly</h3>
              <p>We prioritizes a positive and straightforward user experience</p>
            </div>
            <div className="feature-card">
              <Player
                autoplay
                loop
                src="https://lottie.host/9d5dd77e-6493-4df6-a3c3-7562cc8e302f/hqwRU9dDAb.json"
              >
              </Player>
              <h3>Referral Bonus</h3>
              <p>Earn a referral bonus by sharing with friends. Enjoy bonuses in your account.</p>
            </div>


            <div className="feature-card">
              <Player
                autoplay
                loop
                src="https://lottie.host/ce502c73-2cc0-4725-96f0-7b05e5a97b0f/XS7nknqYTz.json"
              >
              </Player>
              <h3>Fast Withdrawals</h3>
              <p>Secure and Seamless Fast Withdrawal</p>
            </div>

          </div>
        </div>
      </div>
      <div className='justorder-features'>
        <h1 className="why-jo">How to get <span>Started</span> ?</h1>
        <div className='mid-main-container'>
          <div className='yt-wrapper'>
            <YouTube videoId={videoId} opts={opts} onReady={onReady} />
          </div>
          <div className='main-content-wrapper'>
            <div className='main-content'>
              <p>
                To satisfy a wide range of tastes, tc game provides a wide range of more than 1000 games. Along with variety of card and board games players can enjoy favourites like Poker, Fishing , and several kinds of  Slots. Every kind of player can find something they enjoy, whether they prefer short sessions or extended strategic games, interesting and fresh.
              </p>
              <div className='nav-social big'>
                <a href='https://www.instagram.com/super_earn27?igsh=MWYyeDVqNGtkMnpieQ==' target='_blank' rel="noreferrer">Follow <i class="fa-brands fa-instagram"></i></a>
                <a href='https://telegram.dog/superfiewin' target='_blank' rel='noreferrer' >Join <i class="fa-brands fa-telegram"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='payment-section'>
        <h1 className="why-jo left">Payment <span>Proofs</span></h1>

        <div className='gal-items'>
          <div className='img-wrapper'>
            <img alt="payment_proof" src='/Asserts/Payment/1.jpeg' width="100%" />
          </div>
          <div className='img-wrapper'>
            <img alt="payment_proof" src='/Asserts/Payment/2.jpeg' width="100%" />
          </div>
          <div className='img-wrapper'>
            <img alt="payment_proof" src='/Asserts/Payment/3.jpeg' width="100%" />
          </div>
          <div className='img-wrapper'>
            <img alt="payment_proof" src='/Asserts/Payment/4.jpeg' width="100%" />
          </div>
        </div>

      </div>
      <div className='invite-section'>
        <img src='/Asserts/invite.png' alt="invite" width="100%"></img>
      </div>
      <div className='footer'>
        <div className='footer-left'>
          <p>© 2024 colorgame.<span className='span'>me</span></p>
        </div>
        <div className='footer-mid'>
          <p>Contact <a href="mailto:colorgame2703@gmail.com">colorgame2703@gmail.com</a></p>
          <p className='move-top' onClick={scrollToTop}>Move Top <i class="fa-solid fa-arrow-up"></i></p>
        </div>
        <div className='footer-right'>
          <a href='https://www.instagram.com/super_earn27?igsh=MWYyeDVqNGtkMnpieQ==' target='_blank' rel="noreferrer"><i class="fa-brands fa-instagram"></i></a>
          <a href='https://telegram.dog/superfiewin' target='_blank' rel='noreferrer' ><i class="fa-brands fa-telegram"></i></a>
        </div>
      </div>
    </div >
  );
}

export default App;
